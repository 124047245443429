<!--
 * @Author: 候怀烨
 * @Date: 2021-01-06 17:27:06
 * @LastEditTime: 2021-01-06 17:29:52
 * @LastEditors: Please set LastEditors
 * @Description: 库存数据
 * @FilePath: \sd-vue-admin\src\views\project\treasury\depotData\index.vue
-->
<template>
  <router-view></router-view>
</template>

<script>
  export default {
    name: 'DepotData',
  }
</script>

<style></style>
